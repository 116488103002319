<template>
  <HandlerCC />
</template>

<script>
import HandlerCC from "@/components/users/HandlerCC.vue";

export default {
  components: {
    HandlerCC
  }
};
</script>
